import React from "react"

export default function Home() {
  return (
    <div className="main-wrapper">
      <div className="main-text">
        <h1 className="h1">Danny Ryan - Senior Front-End Engineer</h1>
        <h2 className="sup">A CSS Wizard with experience in Tech Strategy, Leading Delivery teams and implementing Design Systems.</h2>    
      </div>
    </div>
  )
}
